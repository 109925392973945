import React from 'react';
import './SearchBar.scss'

export default function SearchBar() {
  return (
    <div className="row">
      <div className="col-12 no-padding">
          <div className="search-input">
              <img src="/elements/search.svg"/>
            <input placeholder="Search for articles..."  />
          </div>
      </div>
      <div className="col-12 col-sm-6 pl-0" >
        <button className="btn btn-primary search-button">Search</button>
      </div>
      <div className="col-12 col-sm-6 pr-0">
        <button className="btn btn-secondary search-button">Reset</button>
      </div>
    </div>
  );
}
