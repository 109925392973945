import React from 'react';
import { graphql } from 'gatsby';
import BlogList from './BlogList'

const BlogPage = ({ data, pageContext }) => (
  <div className="container">
    <BlogList pageContext={pageContext} posts={data && data.allWordpressPost.nodes} />
  </div>
);

export default BlogPage;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allWordpressPost(filter: {categories: {elemMatch: {name: {in: ["eng_posts", "pl_posts"]}}}}, limit: $limit, skip: $skip) {
      nodes {
        title
        excerpt
        slug
        date
        featured_media {
          alt_text
          source_url
        }
        categories {
            name
        }
      }
    }
  }
`;
