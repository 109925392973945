import React from 'react';
import BlogListItem from './BlogListItem';
import SearchBar from '../SearchBar/SearchBar';
import './BlogList.scss';
import BlogPagination from '../BlogPagination/BlogPagination'

export default function BlogList({ posts, pageContext }) {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;
  return (
    <div className="container mb-5">
      <div className="row my-5 align-items-center">
        <div className="col-6">
          <SearchBar />
        </div>
        <div className="col-6 no-padding">
          <h1 className="page-title">Our Blog</h1>
        </div>
      </div>
      <div className="row">
        {posts.map((node) => (
          <BlogListItem key={node.slug} node={node} />
        ))}
      </div>
      <BlogPagination
        numPages={numPages}
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </div>
  );
}
