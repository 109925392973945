import React from 'react';
import './BlogPagination.scss';
import { Link } from 'gatsby';

export default function BlogPagination({ isFirst, isLast, prevPage, nextPage, currentPage, numPages }) {
  return (
    <ul className="pagination-nav">
      {!isFirst && (
        <Link to={prevPage} rel="prev">
          ← Previous Page
        </Link>
      )}
      {Array.from({ length: numPages }, (_, i) => (
        <Link
          to={`/blog/${i === 0 ? '' : i + 1}`}
          className="blog-pagination-link"
          style={{
            color: i + 1 === currentPage ? '#000000' : '',
            textDecoration: i + 1 === currentPage ? '' : 'underline',
          }}
        >
          <li key={`pagination-number${i + 1}`}>{i + 1}</li>
        </Link>
      ))}
      {!isLast && (
        <Link to={nextPage} rel="next">
          Next Page →
        </Link>
      )}
    </ul>
  );
}
