import React, {useEffect, useState} from 'react'
import { Link } from 'gatsby'
import './BlogListItem.scss'
import SanitizedHTML from 'react-sanitized-html'
import placeholder from '../../../static/solveq_logo_narrow_white.jpg';

export default function BlogListItem({ node }) {
  const postCreationDate = Date.parse(node.date)
  const featuredImageUrl = node.featured_media ? node.featured_media.source_url : placeholder;
  const [userLocation, setUserLocation] = useState(false)
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    setUserLocation(location.pathname)
  }, [])
  return (
    <div className="col-12 no-padding blog-list-item">
      <div className="row h-100 no-gutters">
        <div
          className="col-sm-12 col-md-4 blog-list-image"
          style={{ backgroundImage: `url('${featuredImageUrl}')` }}
        />
        <div className="col-sm-12 col-md-8 px-4">
          <div className="blog-list-description">
            <Link to={`/blog/${node.slug}`} state={{ prevPath: userLocation && userLocation.pathname }}>
              <h4>
                <SanitizedHTML html={node.title} />
              </h4>
            </Link>
            <div className="blog-list-tags"/>
            <SanitizedHTML html={node.excerpt} />
          </div>
          <div className="blog-list-read-more">
            <div>Posted {new Intl.DateTimeFormat('en-GB').format(postCreationDate)}</div>
            <Link to={`/blog/${node.slug}`} state={{ prevPath: userLocation && userLocation.pathname }}>
              <div className="btn btn-primary">Read more</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
